// Admin Portal Api Routes...
const menu = '/admin-portal/menu/'
export const menuList = menu + 'list'
export const menuStore = menu + 'store'
export const menuUpdate = menu + 'update'
export const menuToggle = menu + 'toggle-status'

const commonPage = '/admin-portal/common-page/'
export const commonPageList = commonPage + 'list'
export const commonPageStore = commonPage + 'store'
export const commonPageUpdate = commonPage + 'update'
export const commonPageToggle = commonPage + 'toggle-status'
export const deleteAttachment = commonPage + 'delete-file'

const notice = '/admin-portal/notice/'
export const noticeList = notice + 'list'
export const noticeStore = notice + 'store'
export const noticeUpdate = notice + 'update'
export const noticeDetailsApi = notice + 'show'
export const noticeToggleApi = notice + 'toggle-status'

const news = '/admin-portal/news/'
export const newsList = news + 'list'
export const newsStore = news + 'store'
export const newsUpdate = news + 'update'
export const newsDetailsApi = news + 'show'
export const newsToggleApi = news + 'toggle-status'

const entityType = '/admin-portal/entity-type/'
export const entityTypeList = entityType + 'list'
export const entityTypeStore = entityType + 'store'
export const entityTypeUpdate = entityType + 'update'
export const entityTypeToggleApi = entityType + 'toggle-status'

const sector = '/admin-portal/entity-sector/'
export const entitySectorList = sector + 'list'
export const entitySectorStore = sector + 'store'
export const entitySectorUpdate = sector + 'update'
export const entitySectorToggleApi = sector + 'toggle-status'

const subSector = '/admin-portal/entity-sub-sector/'
export const entitySubSectorList = subSector + 'list'
export const entitySubSectorStore = subSector + 'store'
export const entitySubSectorUpdate = subSector + 'update'
export const entitySubSectorToggleApi = subSector + 'toggle-status'
// law Entry
const lawEntry = '/admin-portal/law/'
export const lawEntryList = lawEntry + 'list'
export const lawEntryStore = lawEntry + 'store'
export const lawEntryUpdate = lawEntry + 'update'
export const lawEntryToggleApi = lawEntry + 'toggle-status'

const faq = '/admin-portal/faq/'
export const faqList = faq + 'list'
export const faqStore = faq + 'store'
export const faqUpdate = faq + 'update'
export const faqToggleApi = faq + 'toggle-status'
// Slider Entry
const sliderEntry = '/admin-portal/slider/'
export const sliderList = sliderEntry + 'list'
export const sliderStore = sliderEntry + 'store'
export const sliderUpdate = sliderEntry + 'update'
export const sliderToggleApi = sliderEntry + 'toggle-status'

const activity = '/admin-portal/activity/'
export const activityList = activity + 'list'
export const activityStore = activity + 'store'
export const activityUpdate = activity + 'update'
export const activityToggleApi = activity + 'toggle-status'

const contact = '/admin-portal/contact-office/'
export const contactList = contact + 'list'
export const contactStore = contact + 'store'
export const contactUpdate = contact + 'update'
export const contactToggleApi = contact + 'toggle-status'
